@import "../config"
@import "/styles/config"

.projects__container
    margin-bottom: 40px
    +max-width($mobail)
        margin-bottom: 24px

    &-link
        font: 400 22px/150% $font
        +max-width($mobail)
            font: 400 14px/140% $font

    &-list
        margin: 0 0 60px 0


.case-container
    margin: 0 0 120px 0
    +max-width($lg-tablet)
        margin: 0 0 60px 0
