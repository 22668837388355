@import "../config"
@import "/styles/config"

.new-project

    &__item
        display: flex
        width: 100%
        justify-content: space-between
        align-items: center
        gap: 80px
        color: #2A2D33
        transition: color 0.3s ease
        cursor: pointer
        max-width: 1320px
        +min-width($xl-desktop)
            min-height: 515px
        +max-width($lg-tablet)
            gap: 60px
        +max-width($tablet)
            gap: 40px
        +max-width($mobail)
            flex-direction: column
            gap: 20px

        &:hover,
        &:focus
            color: $hover
            +max-width($mobail)
                color: #2A2D33


    &__video
        display: flex
        width: 100%


    &__block
        width: 40%
        max-width: 440px
        +max-width($mobail)
            width: 100%
            max-width: 100%

    &__icons
        display: flex
        gap: 40px
        margin: 0 -10px 12px
        padding: 0 10px
        +max-width($mobail)
            gap: 10px

    &__ListNew
        display: flex
        margin: 0 10px 12px
        gap: 7px
    &__titleNew
        display: flex
        justify-content: center
        align-items: center
        height: 33px
        padding: 0 12px
        border-radius: 8px
        background-color: $main-color
        font: 400 18px/140% $font
        color: $white

    &__title
        font: 600 32px/125% $font
        margin-bottom: 24px
        +max-width($lg-tablet)
            font: 600 24px/120% $font
        +max-width($mobail)
            font: 600 22px/120% $font
            margin-bottom: 12px

    &__description
        font: 400 22px/145% $font
        +max-width($lg-tablet)
            font: 400 18px/130% $font
        +max-width($mobail)
            font: 400 18px/130% $font


    &__imageContainer
        max-width: 800px
        max-height: 520px
        width: 60%
        +max-width($mobail)
            width: 100%


.imageContainer
    position: relative
    display: block
    max-width: 800px
    height: 100%
    max-height: 520px
    width: 60%
    +max-width($mobail)
        width: 100%

.imageOld
    margin: 0 auto
    height: 400px !important
    max-width: 300px
    object-fit: contain
    +max-width($lg-tablet)
        max-height: 300px !important
    +max-width($tablet)
        max-height: 250px !important
    +max-width($mobail)
        max-height: 200px !important

.imageNew
    width: 100%
    height: auto
